import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import ContentWrapper from 'Component/ContentWrapper';
import CmsBlock from 'Component/CmsBlock';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import history from 'Util/History';
import { CheckoutStepUrl } from 'Route/OnepageCheckout/OnepageCheckout.config';

import './external_script';
import './Footer.style';

/** @namespace AlmusbahblendPwa/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    // TODO implement logic
    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms = undefined } = {} } = window.contentConfiguration || {};
        
        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                    {/* { this.renderColumn({
                        ...NEWSLETTER_COLUMN,
                        mods: { isNewsletter: true },
                    }) } */}
                </div>
            </div>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
               {/* <span block="Footer" elem="Copyright">
                    { copyright }
                </span> */}
            </ContentWrapper>
        );
    }

    render() {
        const {
            location: {
                pathname,
            },
        } = history;

        const isCheckout = pathname.includes(CheckoutStepUrl.CHECKOUT_URL);

        if (isCheckout) {
            return null;
        }

        // console.log("Footer props: ", this.props);
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }


        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label="Footer">
                    { this.renderContent() }
                    {/* this.renderCopyrightContent() */ }
                    
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
